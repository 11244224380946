.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(194, 194, 194, 0.863);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .popup-content {
    position: relative;
    background-color: #fff;
    padding: 50px;
    border-radius: 8px;
    text-align: center;
  }
  
  .close {
    position: absolute;
    top: 5px;
    right: 20px;
    font-size: 40px;
    cursor: pointer;
    color: #e20b0b;
  }
  .image {
    width: 400px;
    height: auto;
  }
  .button {
    position: absolute;
    top: 385px;
    right: 175px;
    padding: 10px 20px; 
    border: 2px solid #CE7373; 
    border-radius: 20px; 
    background-color: #FFFFFF; 
    color: #5FA180; 
    text-align: center;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    cursor: pointer; 
    text-decoration: none; 
    display: inline-block; 
    font-family: Arial, sans-serif;
    opacity: 0.7;
  }
  
  button:hover{
    opacity: 1;
  }
  @media screen and (max-width: 480px) {
    .image {
        width: 200px;
        height: auto;
    }
    .button {
        position: absolute;
        top: 230px;
        right: 80px;
        padding: 10px 20px; 
        border: 2px solid #CE7373; 
        border-radius: 20px; 
        background-color: #FFFFFF; 
        color: #5FA180; 
        text-align: center;
        font-weight: bold;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
        cursor: pointer; 
        text-decoration: none; 
        display: inline-block; 
        font-family: Arial, sans-serif;
        opacity: 0.7;
      }
  }